import React from 'react';
import styled from 'styled-components';
import SEO from "../components/seo"
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import {Grid} from '@material-ui/core';
import {Link} from 'gatsby';


const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const StorySection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;
`

const StoryContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 10rem;
  position: relative;
  /* padding-left: 80px; */


  .links {
    margin-top: 40px;
  }

  @media ${props => props.theme.breaks.down('sm')} {

    .MuiGrid-spacing-xs-8 > .MuiGrid-item {
      padding: 10px 30px;
    }
  }

`

const FourOFour = styled.h1`
  color: ${props => props.theme.colors.primary};
  font-size: 20rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 12rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 8rem;
  }
`

const Explain = styled.h5`
  margin-top: 50px;
  color: ${props => props.theme.colors.body};
  font-size: 3rem;
  font-weight: 300;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 2.5rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 1.8rem;
  }
`

const LinkButton = styled(Link)`
  text-decoration: none;
  display: inline-block;
  padding: 15px 5px;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 100%;
  cursor: pointer;
  font-weight: 300;
  font-size: 1.6rem;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`


const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 60px auto 0 auto;
  padding-left: 40px;
  bottom: 1rem;
  position: fixed;
  background-color: ${props => props.theme.colors.dark};

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`


const NotFoundPage = ({ data }) => {

  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body}>
        <SEO title="404: Not found" />
      <Container>
        
        <StorySection>

          <StoryContainer>

            <FourOFour>404</FourOFour>
            <Explain>That means we couldn’t find what you’re looking for. Oh, well.</Explain>
            
            <Grid container className="links" justify="center" spacing={8}>
              <Grid item xs={12} md={4}><LinkButton to="/#celebrand">Read About Branding</LinkButton></Grid>
              <Grid item xs={12} md={4}><LinkButton to="/look-at-a-duck">Look at a Duck Instead</LinkButton></Grid>
            </Grid>
          </StoryContainer>
          
        
         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

         </StorySection>

        
      </Container>
      </Layout>
    
)
}

export default NotFoundPage;
